///////////////////////////////////
/// Special Table | .tb-ftr
///////////////////////////////////
.tb-ftr {
    &-head {
        th {
            padding-top: 0.75rem;
            padding-bottom: 0.75rem;
            .table & {
                font-size: $fx-sz-12;
                color: $base-light;
                line-height: 1.5;
                text-transform: uppercase;
                letter-spacing: 0.12em;
            }
        }
    }
    &-item {
        td {
            border-top: 0;
        }
    }
    &-info {
        font-weight: $fw-medium;
        width: 60%;
    }
    &-plan {
        text-align: center;
        .plan-name {
            display: none;
        }
        .icon {
            color: $accent-color;
        }
    }
}

@media (max-width: 575px){
    .tb-ftr {
        &-item {
            display: flex;
            flex-wrap: wrap;
            border-top: 1px solid $border-light;
            padding: 0.75rem 1.25rem;
            .tb-ftr-head & {
                padding: 0;
            }
            &:first-child {
                border-top: 0;
            }
            td {
                .table & {
                    padding:0.25rem 0;
                }
            }
            .tb-ftr-plan {
                .table & {
                    @if($rtl==false){
                        padding-right: 1.25rem;
                    }
                    @if($rtl==true){
                        padding-left: 1.25rem;
                    }
                }
            }
        }
        &-info {
            width: 100%;
        }
        &-plan {
            .icon {
                font-size: 1rem;
                vertical-align: middle;
                @if($rtl==false){
                    margin-right: .25rem;
                }
                @if($rtl==true){
                    margin-left: .25rem;
                }
            }
            .plan-name {
                display: inline;
                vertical-align: middle;
                line-height: 1.3;
                font-size: $fx-sz-12;
            }
            .tb-ftr-head & {
                display: none;
            }
        }
    }
}
@media (min-width: 575px){
    .tb-ftr {
        &-item {
            &:first-child {
                td {
                    padding-top: 1.25rem;
                }
            }
            &:last-child {
                td {
                    padding-bottom: 1.25rem;
                }
            }
        }
    }
}