/// Blocks
///////////////////////////
.nk{
    &-block{
        .nk-block + &, + .nk-block-head {
            padding-top: $gutter-width;
            &-lg{
                padding-top: 2.5rem;
            }
        }
        .nav-tabs + &{
            padding-top: 1.5rem;
            &-sm{
                padding-top: 1rem;
            }
        }
        &-area{
            display: flex;
            &-column{
                flex-direction: column;
            }
        }
        &-between{
            display: flex;
            justify-content: space-between;
            align-items: center;
            > .title:first-child {
                margin-bottom: 0;
            }
        }
        &-middle{
            margin-top: auto;
            margin-bottom: auto;
        }
        &-head{
            position: relative;
            padding-bottom: 1.25rem;
            &:only-child{
                padding-bottom: 0;
            }
            h2:not(:last-child){
                margin-bottom: 1rem;
            }
            &-sub{
                font-size: 1rem;
                line-height: 1.5rem;
                margin-bottom: .5rem;
                color: $base-light;
                font-weight: $fw-normal;
                position: relative;
                .dropdown {
                    position: absolute;
                    @if($rtl==false){
                        right: -8px;
                    }
                    @if($rtl==true){
                        left: -8px;
                    }
                    top: -6px;
                }
            }
            &-xs{
                padding-bottom: .75rem;
            }
            &-sm{
                padding-bottom: 1rem;
            }
            &-lg{
                padding-bottom: 1.5rem;
            }
            & + .nav-tabs {
                margin-top: -1rem;
            }
            .nk-content-body > & {
                &:first-child {
                    padding-bottom: 1.75rem;
                }
            }
            .nk-block-text{
                margin-top: 1.5rem;
                + .btn{
                    margin-top: 1.5rem;
                }
            }
        }
        &-tools{
            display: flex;
            align-items: center;
            > *{
                display: inline-flex;
            }
        }
        &-des{
            color: $base-text;
            strong{
                color: $base-color;
            }
            .icon{
                vertical-align: middle;
            }
        }
        &-content{
            + .nk-block-head{
                padding-top: 2rem;
                &-sm{
                    padding-top: 1.5rem;
                }
            }
            &-head{
                &:not(:last-child){
                    margin-bottom: 1rem;
                }
            }
        }
        &-title {
            &-group {
                display: flex;
                justify-content: space-between;
                align-items: center;
                .title {
                    margin-bottom: 0
                }
                &:not(:last-child) {
                    margin-bottom: .5rem;
                }
            }
        }
    }
}
@include media-breakpoint-up(sm){
    .nk{
        &-block{
            &-head{
                &-md{
                    padding-bottom: 1.75rem;
                }
                &-lg{
                    padding-bottom: 2.5rem;
                }
            }
            &-content{
                + .nk-block-head{
                    padding-top: 4rem;
                    &-sm{
                        padding-top: 2.5rem;
                    }
                }
            }
        }
    }
}
@include media-breakpoint-up(md){
    .nk-block{
        &-head {
            .nk-content-body > & {
                &:first-child {
                    padding-bottom: 2.5rem;
                }
                &-sm{
                    &:first-child {
                        padding-bottom: 1.75rem;
                    }
                }
            }
        }
        .nav-tabs + & {
            padding-top: 2.5rem;
            &-sm{
                padding-top: 2rem;
            }
            &-xs{
                padding-top: 1.25rem;
            }
        }
        &-text {
            h5, h6 {
                font-size: 1rem;
            }
        }
    }
}

@include media-breakpoint-down(sm){
    .nk-block-tools{
        .opt-menu-md{
            order: 100;
            @if($rtl==false){
                margin-left: auto;
            }
            @if($rtl==true){
                margin-right: auto;
            }
        }
    }
}

$breaks: md;
@each $break in $breaks {
    @include media-breakpoint-up($break){
        .nk-block{
            &-between-#{$break}{
                display: flex;
                justify-content: space-between;
                align-items: center;
            }
        }
    }
}

.back-to{
    color:inherit;
    display: inline-flex;
    align-items: center;
    .icon{
        font-size: 1.5rem;
        width: 2rem;
        margin-top: -3px;
        display: inline-block;
    }
}
.nk-block-subhead{
    padding-bottom: .5rem;
    border-bottom: 1px solid $border-color;
}