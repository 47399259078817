$w_100: 100%;
$li_cols: 2,3,4;
@each $li_col in $li_cols {
    .li-col#{$li_col}x{
        > li {
            width: ($w_100/$li_col);
            @if($rtl==false){
                float: left;
            }
            @if($rtl==true){
                float: right;
            }
        }
    }
}