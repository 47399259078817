/// Member Table
///////////////////////////////
.tb-member {
	&-item {
		td {
			vertical-align: middle;
			padding-top: 1rem;
			padding-bottom: 1rem;
		}
		&:first-child {
			td:first-child {
				border-radius: $border-radius;
			}
		}
		.user-card .user-info {
			flex-grow: 1;
		}
	}
	&-role {
		width: 150px;
	}
	&-info {
		width: 40%;
	}
	&-action {
		@if($rtl==false){
			text-align: right;
		}
		@if($rtl==true){
			text-align: left;
		}
		div > .link {
			@if($rtl==false){
				margin-left: .75rem;
			}
			@if($rtl==true){
				margin-right: .75rem;
			}
		}
	}
}
@media (max-width: 992px) {
	.tb-member {
		&-action {
			width: 150px;
		}
	}
}
@media (max-width: 767px) {
	.tb-member {
		&-info {
			width: 60%;
		}
	}
}
@media (max-width: 575px) {
	.tb-member {
		&-item {
			display: flex;
		}
		&-info {
			width: auto;
			flex-grow: 1;
		}
		&-action {
			@if($rtl==false){
				margin-left: auto;
			}
			@if($rtl==true){
				margin-right: auto;
			}
			width: auto;
			&:last-child {
				.table .tb-member-body & {
					@if($rtl==false){
						padding-right: .5rem;
					}
					@if($rtl==true){
						padding-left: .5rem;
					}
				}
			}
		}
	}
}
@media (max-width: 374px) {
	.tb-member {
		&-item .user-card {
			max-width: 200px;
			.sub-text {
				width: 96%;
				white-space: nowrap;
				overflow: hidden;
				text-overflow: ellipsis;
			}
		}
	}
}