/// Global Auths
/////////////////////////////

.nk-auth{
    &-body{
        padding: 1.25rem;
    }
    &-footer{
        padding-bottom: 40px;
    }
    &-body,&-footer{
        width: 100%;
        max-width: 420px;
        margin-left: auto;
        margin-right: auto;
    }
    &-footer{
        padding-left: 1.25rem;
        padding-right: 1.25rem;
    }
    &-footer-full{
        margin-top: 0;
    }
}

@include media-breakpoint-up(lg){
    .nk-auth{
        &-container{
            width: 45%;
        }
    }
}
@include media-breakpoint-up(xxl){
    .nk-auth{
        &-body,&-footer{
            .nk-split & {
                @if($rtl==false){
                    margin-right: 90px;
                }
                @if($rtl==true){
                    margin-left: 90px;
                }
            }
        }
    }
}