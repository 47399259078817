///// Shadow Variable //////////
$softy_shadow: 0 2px 6px -1px rgba($dark, 0.08);

// SOFTY STYLE
.ui-softy {
    .table .thead-light th {
        background: rgba($lighter, .5);
        box-shadow: 0 -1px 5px -5px rgba($dark, 0.7);
    }
    .tb-tnx-head, .sp-plan-action, .tb-ticket-item.is-unread, .support-topic-block:link:hover, .nk-refwg-stats {
        background: rgba($lighter, .5);
    }
    .nk-refwg-stats {
        background: rgba($lighter, .5) !important;
    }
    .card .card-inner + .table .tb-tnx-head {
        border-top: 1px solid $border-color;
    }
    .nk-header {
        box-shadow: 0 1px 3px rgba($darker, .06);
    }
}

.ui-softy {
    .card,
    .code-block,
    .sp-pdl-item,
    .invoice-wrap,
    .accordion:not(.accordion-s2):not(.accordion-s3),
    .nk-reply-form,
    .nk-files-view-grid .nk-file, .nk-files-view-group .nk-file, .nk-files-view-list .nk-files-list, 
    .chat-msg, .kanban-board-header, .kanban-item, .kanban-add-task
    {
        box-shadow: $softy_shadow;
    }
    .card .accordion:not(.accordion-s2):not(.accordion-s3) {
        box-shadow: none;
        border: 1px solid $border-color;
    }
} 

// Apps
.ui-softy {
    .nk-msg, .nk-ibx, .nk-fmg, .nk-chat{
        box-shadow: $softy_shadow;
    }
} 

.ui-softy{
    .nk-msg-aside, .nk-msg-nav{
        background: $white;
    }
    .nk-msg-item.active, .nk-msg-item.current {
        background: rgba($lighter, .5);
        position: relative;
        &:after {
            position: absolute;
            content: "";
            @if($rtl==false){
                left: 0;
            }
            @if($rtl==true){
                right: 0;
            }
            top: 0;
            height: 100%;
            width: 2px;
            background: $accent-color;
        }
    }
    .nk-msg-item.active, .nk-msg-item.current {
        background: $lighter;
        &:after {
            width: 3px;
        }
    }
    .nk-msg-reply {
        box-shadow: inset 0 0 8px -5px rgba($darker, .3);
    } 

    .nk-ibx-reply, .nk-chat-panel {
        box-shadow: inset 0 0 8px -5px rgba($darker, .3);
    }
    .nk-ibx-item {
        box-shadow: 0 0 7px -1px rgba($darker, .1);
    }
}

.ui-rounder{
    .card,.embed-responsive{
        border-radius: $border-radius-xxl;
    }
}