.nk{
    &-split{
        position: relative;
        display: flex;
        &-page{
            min-height: 100vh;
        }
        &-content{
            position: relative;
            flex-shrink: 0;
            width: 100%;
            min-height: 100%;
            &-md{
                @include media-breakpoint-up(md){
                    width: 642px;
                }
            }
        }
        &-stretch{
            flex-grow: 1;
            flex-shrink: 1;
        }
    }
}

@include media-breakpoint-down(md){
    .nk-split-stretch{
        &.toggle-break-lg{
            width: 460px;
        }
    }
}

.nk-split {
    &-sm{
        @include media-breakpoint-down(xs){
            flex-wrap: wrap;
        }
    }
    &-md{
        @include media-breakpoint-down(sm){
            flex-wrap: wrap;
        }
    }
    &-lg{
        @include media-breakpoint-down(md){
            flex-wrap: wrap;
        }
    }
    &-xl{
        @include media-breakpoint-down(lg){
            flex-wrap: wrap;
        }
    }
    &-xxl{
        @include media-breakpoint-down(xl){
            flex-wrap: wrap;
        }
    }
}
